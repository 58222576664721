import './Sustainability.scss';
import AnchorButton from '../../components/Button/AnchorButton';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
}

const contentBlocks: ContentBlockData[] = [
  {
    text: 'Bei der Defender Experience auf Sylt hat JLR auf Nachhaltigkeit gesetzt, indem vielfach Möbel aus vorherigen Events wiederverwendet oder nachhaltig produzierte Möbel eingesetzt wurden. Diese Maßnahmen unterstreichen das Engagement des Unternehmens, Ressourcen zu schonen und umweltbewusst zu handeln.',
    title: 'Umweltfreundliche Möblierung',
  },
  {
    text: 'Wir schätzen ihre Bemühungen, uns dabei zu helfen, umweltfreundlicher zu werden. Bitte achten Sie beim Besuch der Defender Lounge darauf, Wasser und Energie zu sparen. Gemeinsam können wir einen Unterschied für unsere Umwelt machen.',
    title: 'Strom- und wasserverbrauch',
  },
  {
    text: 'Bitte schließen Sie sich uns an und nutzen Sie die entsprechenden Behälter zur Mülltrennung. Wenn wir zusammenarbeiten, können wir unsere Umweltbelastung reduzieren und eine nachhaltigere Zukunft für alle unterstützen.',
    title: 'Mülltrennung',
  },
  {
    text: 'JLR führt für seine Modelle FSC-zertifizierte Naturgummireifen ein. Diese Reifen fördern den nachhaltigen Anbau und die umweltfreundliche Gewinnung von Naturgummi. Mit der Zertifizierung durch den Forest Stewardship Council (FSC) setzt JLR ein klares Zeichen für verantwortungsbewussten Ressourceneinsatz und Umweltschutz.',
    title: 'FSC-zertifizierte Naturgummireifen für Nachhaltigkeit',
    url: 'https://media.jaguarlandrover.com/de-de/news/2024/07/jlr-fuehrt-fuer-seine-modelle-fsc-zertifizierte-naturgummi-reifen-ein',
  },
  {
    text: 'JLR plant, über ein Viertel seines in Großbritannien verbrauchten Stroms durch erneuerbare Energiequellen zu decken. Diese Initiative unterstützt die Reduzierung der CO2-Emissionen und fördert die Nutzung sauberer Energie. Durch Investitionen in erneuerbare Energien stärkt JLR seine Position als Vorreiter im Umweltschutz.',
    title: 'Nachhaltige Energieversorgung in Großbritannien',
    url: 'https://media.jaguarlandrover.com/de-de/news/2024/03/jlr-will-ueber-ein-viertel-seines-grossbritannien-verbrauchten-stroms-durch',
  },
  {
    text: 'JLR hat ehrgeizige Nachhaltigkeitsziele bis 2030 bekannt gegeben. Das Unternehmen strebt an, seine CO2-Emissionen erheblich zu reduzieren und die Umweltverträglichkeit seiner Produkte zu verbessern. Dazu gehören Maßnahmen zur Emissionsminderung in der Produktion sowie die Entwicklung von umweltfreundlicheren Fahrzeugen und Technologien.',
    title: 'JLR setzt Nachhaltigkeitsziele bis 2030',
    url: 'https://media.jaguarlandrover.com/de-de/news/2022/03/jaguar-land-rover-gibt-nachhaltigkeitsziele-bis-2030-bekannt',
  },
  {
    text: 'JLR kooperiert für die Defender Experience auf Sylt mit dem Hotel Severin*s. Das Hotel engagiert sich für Nachhaltigkeit durch die Zusammenarbeit mit der Greensign Initiative, die umweltfreundliche Praktiken und Standards fördert. Diese Partnerschaft betont JLRs Einsatz für nachhaltige und verantwortungsbewusste Projekte.',
    title: 'Nachhaltige Kooperation: JLR und Hotel Severin*s auf Sylt',
    url: 'https://www.severins-sylt.de/severins/was-uns-auszeichnet/',
  },
  {
    text: 'Im Rahmen der Defender Experience auf Sylt kooperiert JLR mit dem Restaurant Sylter Royal. Das Restaurant setzt auf Nachhaltigkeit durch regionale Produkte und umweltbewusste Praktiken. Diese Partnerschaft unterstützt das Engagement von JLR für umweltfreundliche und verantwortungsbewusste Initiativen.',
    title: 'Nachhaltige Partnerschaft mit Sylter Royal',
    url: 'https://sylter-royal.de/unsere-arbeit/',
  },
];

function Sustainability(props: Props) {
  const scope = 'Sustainability';

  const systemDevice = useSystemDevice();
  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  return (
    <div className={className}>
      <Header />
      <div className={`${scope}-lightGreySection`}>
        <div className={`${scope}-contentBlocks`}>
          {contentBlocks.map((item, index) => (
            <div className={`${scope}-contentBlock`} key={index}>
              <h3 className={`${scope}-contentBlockTitle`}>{item.title}</h3>
              <div className={`${scope}-contentBlockText`}>{item.text}</div>
              {item.url && (
                <div className={`${scope}-contentBlockButtonContainer`}>
                  <AnchorButton
                    className={`${scope}-contentBlockButton`}
                    href={item.url}
                    rel="noreferrer"
                    target="_blank"
                    text="Mehr erfahren"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

interface ContentBlockData {
  text: string;
  title: string;
  url?: string;
}

export default Sustainability;
