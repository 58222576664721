import assert from 'assert';
import dayjs from 'dayjs';
import { EventTypeModel } from './EventTypeModel';
import { ApiEvent, origin } from './api';

export class EventInstanceModel {
  private static apiData: ApiEvent[] = [];

  private static loadPromise?: Promise<EventInstanceModel[]>;

  private static models = new Map<number, EventInstanceModel>();

  constructor(private _id: number) {}

  get attrs() {
    const apiData = EventInstanceModel.apiData.find(
      ({ eventId }) => eventId === this.id,
    );
    assert(apiData, `API data not found for event id: ${this.id}`);
    return apiData;
  }

  get id() {
    return this._id;
  }

  get time() {
    return {
      start: new Date(this.attrs.time.start * 1000),
      end: new Date(this.attrs.time.end * 1000),
    };
  }

  get type() {
    return EventTypeModel.findById(this.typeId);
  }

  get typeId() {
    const url = new URL(this.attrs.eventTypeLink);
    return url.searchParams.get('eventType') ?? '';
  }

  static find(this: void, id: number) {
    const apiData = EventInstanceModel.apiData.find(
      ({ eventId }) => eventId === id,
    );
    if (!apiData) {
      return null;
    }
    let model = EventInstanceModel.models.get(id);
    if (!model) {
      model = new EventInstanceModel(id);
      EventInstanceModel.models.set(id, model);
    }
    return model;
  }

  static findAll(this: void) {
    return EventInstanceModel.apiData.map(({ eventId }) => {
      const model = EventInstanceModel.find(eventId);
      assert(model, `EventInstanceModel not found for event id: ${eventId}`);
      return model;
    });
  }

  static loadData(this: void) {
    let promise = EventInstanceModel.loadPromise;
    if (!promise) {
      const headers = new Headers();
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      if (username && password) {
        const authorizationValue = btoa(`${username}:${password}`);
        headers.append('Authorization', `Basic ${authorizationValue}`);
      }

      promise = fetch(`${origin}/events`, {
        headers,
        mode: 'cors',
      })
        .then((response) => response.json())
        .then((apiEvents: ApiEvent[]) => {
          const kiteCup = EventTypeModel.find('kite-cup');
          const kiteCupEvents = [22, 23, 24, 25, 26, 27].map((day) => {
            return {
              eventId: -day,
              eventName: kiteCup.attrs.name,
              eventTypeLink:
                'https://www.defender-sylt.de/events/?eventType=kite-cup',
              signUpLink: '',
              slots: {
                available: 0,
                total: 0,
              },
              status: '',
              time: {
                start: dayjs(`2023-08-${day} 00:00`).valueOf() / 1000,
                end: dayjs(`2023-08-${day} 00:00`).valueOf() / 1000 - 1,
              },
            };
          });
          EventInstanceModel.apiData = [...apiEvents, ...kiteCupEvents];
          return EventInstanceModel.findAll();
        });
      EventInstanceModel.loadPromise = promise;
    }
    return promise;
  }
}
