import assert from 'assert';
import dayjs from 'dayjs';
import { SYSTEM_DEVICE } from '../hooks/useSystemDevice';
import lottieKiteCup from '../lottie/kite-cup.json';
import lottieKiteWorkshop from '../lottie/kite-workshop.json';
import lottieMudflatHiking from '../lottie/mudflat-hiking.json';
import lottieNightskyGazing from '../lottie/nightsky-gazing.json';
import lottieTestDrive from '../lottie/test-drive.json';
import { EventInstanceModel } from './EventInstanceModel';

export class EventTypeModel {
  private static models = new Map<EventTypeKey, EventTypeModel>();

  private constructor(private _key: EventTypeKey) {}

  get attrs() {
    const result = localData.find(({ key }) => key === this.key);
    assert(result, `Local data not found for event type key: ${this.key}`);
    return result;
  }

  get eventTypeLink() {
    return `https://www.defender-sylt.de/events/?eventType=${this.attrs.id}`;
  }

  get events() {
    return EventInstanceModel.findAll()
      .filter((event) => {
        return event.typeId === this.attrs.id;
      })
      .sort((event1, event2) => {
        return event1.time.start.valueOf() - event2.time.start.valueOf();
      });
  }

  get key() {
    return this._key;
  }

  get linkPath() {
    return `/event/${this.key}`;
  }

  get weekDays() {
    const allWeekdays = this.events.map((event) => {
      return dayjs(event.time.start).format('dd');
    });
    const uniqueWeekdays = allWeekdays.filter((weekDay, index, self) => {
      return self.indexOf(weekDay) === index;
    });
    return uniqueWeekdays.join('–');
  }

  static find(this: void, key: EventTypeKey) {
    let model = EventTypeModel.models.get(key);
    if (!model) {
      model = new EventTypeModel(key);
      EventTypeModel.models.set(key, model);
    }
    return model;
  }

  static findAll(this: void) {
    return localData.map(({ key }) => EventTypeModel.find(key));
  }

  static findById(this: void, id: string) {
    return EventTypeModel.findAll().find(({ attrs }) => attrs.id === id);
  }

  getImageSrc(type: EventImageType, device: SYSTEM_DEVICE) {
    return `${process.env.PUBLIC_URL}/media/events/${this.key}/${device}/${type}.webp`;
  }
}

const eventTypeKeys = [
  'kite-cup',
  'kite-workshop',
  'mudflat-hiking',
  'nightsky-gazing',
  'test-drive',
] as const;

export type EventTypeKey = (typeof eventTypeKeys)[number];

export function isValidEventKey(key: unknown): key is EventTypeKey {
  return eventTypeKeys.includes(key as EventTypeKey);
}

export interface EventTypeAttributes {
  animationData: unknown;
  duration?: string;
  extraInfo?: {
    subtitle?: string;
    text: string | string[];
    title: string;
  };
  id: string;
  info?: {
    text: string | string[];
    title: string;
  };
  instructor?: {
    firstName: string;
    intro: string;
    lastName: string;
  };
  key: EventTypeKey;
  mapInfo?: {
    text: string | string[];
    title: string;
  };
  meetingPoint?: string;
  name: string;
  positionOnMap: {
    bottom: number;
    left: number;
  };
  programSelectorText: string;
  recommendedEventsKeys: EventTypeKey[];
}

export type EventImageType = 'main' | 'program-selector';

const localData: EventTypeAttributes[] = [
  {
    animationData: lottieKiteWorkshop,
    duration: '3h',
    id: '5ef1d4596307ac4ba09981ff00e9dd60e6a81894f3b234a42b570839dfeefb5e',
    info: {
      text: 'Erleben Sie unvergessliche Momente auch außerhalb des Defenders, wenn Sie mit Frank Debus auf den Wellen reiten. In kleiner Runde bauen Sie auf Ihre bestehenden Kiteboard-Grundlagen auf und verbringen Stunden voller Spannung und Vergnügen. Der Kitesurf Workshop vermittelt Ihnen wertvolle Praxiseinblicke und einzigartige Momente auf den Wellen Sylts.',
      title: 'Für die perfekte welle',
    },
    instructor: {
      firstName: 'Frank',
      lastName: 'Debus',
      intro:
        'Seit über 30 Jahren gibt Frank Debus, unser professioneller Kitesurf-Guide, bei jedem Wetter seine Leidenschaft für den Wassersport an Surfer aller Erfahrungsstufen weiter. In seinem Workshop erhalten Sie unverzichtbare Tipps und Techniken, um Ihre Kitesurf-Künste gezielt zu verbessern.',
    },
    key: 'kite-workshop',
    mapInfo: {
      title: 'Sylter Ellenbogen',
      text: 'Unser Kitesurf Workshop findet am Ellenbogen Sylts, dem idealen Ort für Wassersport statt. Fühlen Sie die Kraft des Windes und die Freiheit des Wassers. Tauchen Sie in die beeindruckende Küstenlandschaft ein. Lassen Sie sich von der natürlichen Schönheit Sylts und der Spannung des Kitesurfens mitreißen.',
    },
    meetingPoint: 'Defender Lounge, Brandenburger Strand',
    name: 'Kitesurf workshop',
    positionOnMap: {
      bottom: 91,
      left: 58,
    },
    programSelectorText:
      'Erleben Sie unvergessliche Momente mit Kitesurf-Profi Frank Debus. Verbessern Sie Ihre Kiteboard-Fähigkeiten und genießen Sie spannende Stunden auf den Wellen Sylts.',
    recommendedEventsKeys: [
      'test-drive',
      'nightsky-gazing',
      'mudflat-hiking',
      'kite-cup',
    ],
  },
  {
    animationData: lottieTestDrive,
    duration: '1h',
    extraInfo: {
      subtitle: 'Defender lounge',
      text: 'Die Defender Lounge am Strand von Westerland ermöglicht Ihnen den perfekten Start in Ihre Defender Experience. Hier können Sie den Defender hautnah erleben und sich über dieses beeindruckende Modell informieren. Genießen Sie kleine Snacks und Erfrischungen in angenehmer Atmosphäre mit Meerblick und lassen Sie sich auf Ihre bevorstehenden Abenteuer einstimmen. Im Anschluss an ihr jeweiliges Programm, bietet die Defender Lounge die richtige Gelegenheit für einen entspannten Ausklang am Meer.',
      title: 'Für einen ausklang am meer',
    },
    id: 'ae5a5e776772c6ef9954094a91d589ca3c2a41e52e3292eb74b717165105969c',
    info: {
      text: [
        'Kreieren Sie Ihr eigenes Abenteuer und erleben Sie den Defender auf einer unvergesslichen Reise entlang der Insel Sylt. Unsere exklusive Roadmap führt Sie zu den malerischsten Orten der Insel und bietet Ihnen die einzigartige Möglichkeit, den Defender in seinem natürlichen Habitat zu testen.',
        'Spüren Sie die Kraft und Vielseitigkeit des Defender, während Sie die Freiheit und Schönheit der Insel genießen. Lassen Sie sich überraschen und begeistern von der Performance und dem Komfort, den nur ein Defender bieten kann. Wir wünschen Ihnen eine aufregende und unvergessliche Fahrt!',
      ],
      title: 'Für das grosse fahrerlebnis',
    },
    key: 'test-drive',
    mapInfo: {
      title: 'Defender Lounge',
      text: 'Besuchen Sie uns in der Defender Lounge am Brandenburger Strand und genießen diesen angenehmen Rückzugsort vor und nach Ihren Aktivitäten. Nutzen Sie die Möglichkeit und starten hier auch Ihr ganz persönliches Fahrerlebnis mit dem Defender. Planen Sie Ihren Besuch zwischen dem 20. und 25. August? Dann verpassen Sie nicht den Kitesurf World Cup, der entlang des Brandenburger Strandes stattfindet und die perfekte Ergänzung zu Ihrem Sylt-Abenteuer darstellt.',
    },
    meetingPoint: 'Defender Lounge, Brandenburger Strand',
    name: 'Test Drive',
    positionOnMap: {
      bottom: 51,
      left: 0,
    },
    programSelectorText:
      'Testen Sie den Defender auf Sylt! Unsere Roadmap führt Sie zu den schönsten Orten der Insel. Erleben Sie die Freiheit und Vielseitigkeit des Defenders selbst.',
    recommendedEventsKeys: [
      'nightsky-gazing',
      'kite-workshop',
      'mudflat-hiking',
      'kite-cup',
    ],
  },
  {
    animationData: lottieNightskyGazing,
    duration: '2h',
    id: '91a4160f6b0060e515c4fb51c061d34c5648d3ed2aed6b51a78b7da358d23b04',
    info: {
      text: 'Erkunden Sie mit dem Defender die himmlischen Höhen bei unserem Nightsky Gazing. Starten Sie Ihren Abend in der Defender Lounge am Strand von Westerland. Weiter geht es zu den Morsumer Klippen, wo Sie unser Astronom mit seinen Teleskopen erwartet. Lassen Sie sich durch die funkelnde Sternennacht führen und seien Sie im August bereit für den atemberaubenden Perseiden Schauer. Halten Sie für diese Nächte am besten einige Wünsche bereit.',
      title: 'Für die magischen aussichten',
    },
    instructor: {
      firstName: 'Steffen',
      lastName: 'Jahrmarkt',
      intro:
        'Der Sylter Hobbyastronom Steffen Jahrmarkt bringt Ihnen die Faszination am Sternenhimmel näher: Blicken Sie gemeinsam mit ihm durch das Teleskop und erfahren Sie Faktisches und Philosophisches über unseren Kosmos und das Weltall. Je nach Sichtbedingungen beobachten Sie dabei den Mond, unsere Nachbarplaneten oder Galaxien. Im August ist die Chance auf Sternschnuppen besonders hoch, also: Wünsche mitbringen!',
    },
    key: 'nightsky-gazing',
    mapInfo: {
      title: 'Rantumer Hafen',
      text: 'Das Nightsky Gazing findet vor der malerischen Kulisse des Rantumer Hafens auf Sylt statt. Hier erhalten Sie einen unverstellten Blick auf den funkelnden Sternenhimmel.',
    },
    meetingPoint: 'Defender Lounge, Brandenburger Strand',
    name: 'Nightsky gazing',
    positionOnMap: {
      bottom: 23,
      left: 38,
    },
    programSelectorText:
      'Erkunden Sie den Sternenhimmel auf Sylt. Genießen Sie den August mit dem spektakulären Perseiden-Schauer und halten Sie Ihre Wünsche bereit für eine unvergessliche Nacht.',
    recommendedEventsKeys: [
      'mudflat-hiking',
      'kite-workshop',
      'test-drive',
      'kite-cup',
    ],
  },
  {
    animationData: lottieMudflatHiking,
    duration: '3h',
    id: '5bbd82f7628f1110f9f0c5ce77dcdae8e97828cb0ce1741e5843100060623f60',
    info: {
      text: 'Entdecken Sie auf einer Wattwanderung die unglaubliche Welt des Wattenmeeres. Von der Defender Lounge aus geht es zur Kempener Vogelkoje, wo Ihre Wanderung beginnt. Unter der Leitung des erfahrenen Nationalpark-Wattführers Jan Krüger erkunden Sie das Naturschutzgebiet und lernen die Tier- und Pflanzenwelt dieses einzigartigen Biotops kennen.',
      title: 'Für den guten lauf',
    },
    instructor: {
      firstName: 'Jan',
      lastName: 'Krüger',
      intro:
        'Der auf Sylt geborene Jan Krüger ist ein Experte für das Wattenmeer und offizieller Nationalpark-Wattführer. Er wird Ihnen mit profundem Wissen und spielerischem Charme dieses einzigartige Ökosystem näherbringen und Ihnen dabei helfen, einen tiefgreifenden Einblick in diese Umgebung zu gewinnen.',
    },
    key: 'mudflat-hiking',
    mapInfo: {
      title: 'Vogelkoje',
      text: 'Unsere Wattwanderung führt Sie zur Vogelkoje und zum östlich gelegenen Wattenmeer auf Sylt, einem atemberaubenden Naturschutzgebiet mit einer vielfältigen Vogelwelt und unvergleichlicher Schönheit.',
    },
    meetingPoint: 'Defender Lounge, Brandenburger Strand',
    name: 'Wattwandern',
    positionOnMap: {
      bottom: 70,
      left: 60,
    },
    programSelectorText:
      'Entdecken Sie die Welt des Wattenmeeres. Unter Leitung von Nationalpark-Wattführer Jan Krüger erkunden Sie das Naturschutzgebiet und seine einzigartige Tier- und Pflanzenwelt.',
    recommendedEventsKeys: [
      'kite-cup',
      'nightsky-gazing',
      'test-drive',
      'kite-workshop',
    ],
  },
  {
    animationData: lottieKiteCup,
    id: 'kite-cup',
    key: 'kite-cup',
    name: 'Kitesurf World Cup',
    positionOnMap: {
      bottom: 39.5,
      left: 0,
    },
    programSelectorText:
      'Wir begrüßen die Weltelite des Kitesurfens. Besuchen Sie die Defender Lounge für ein passendes Rahmenprogramm und genießen Sie aufregende Tage voller Spannung.',
    recommendedEventsKeys: [
      'kite-workshop',
      'nightsky-gazing',
      'mudflat-hiking',
      'test-drive',
    ],
  },
];
