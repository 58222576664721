import { ReactNode } from 'react';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './Header.scss';

export interface Props {
  children?: ReactNode;
  imageSrc?: string;
}

export default function Header(props: Props) {
  const scope = 'Header';
  const systemDevice = useSystemDevice();
  const navigate = useNavigate();
  const contentImageStyle = props.imageSrc
    ? {
        backgroundImage: `url(${props.imageSrc})`,
      }
    : {};

  return (
    <header className={`${scope} ${scope}--${systemDevice}`}>
      <div className={`${scope}-logoSection`}>
        <button
          className={`${scope}-logoImageButton`}
          type="button"
          onClick={() => navigate('/')}
        >
          <SVG
            aria-label="Defender"
            className={`${scope}-logoImage`}
            src={`${process.env.PUBLIC_URL}/media/defender.svg`}
          />
        </button>
      </div>
      {props.imageSrc && (
        <div className={`${scope}-imageSection`} style={contentImageStyle}>
          <div className={`${scope}-content`}>{props.children}</div>
        </div>
      )}
    </header>
  );
}
