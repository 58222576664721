import React from 'react';
import SVG from 'react-inlinesvg';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './Footer.scss';

export interface Props {
  className?: string;
  imageSrc?: string;
}

const Footer: React.FC<Props> = (props) => {
  const scope = 'Footer';
  const systemDevice = useSystemDevice();

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  return (
    <footer className={className}>
      <div className={`${scope}-content`}>
        <div className={`${scope}-socialLinks`}>
          <a
            className={`${scope}-socialLink`}
            href="https://www.landrover.de/"
            rel="noreferrer"
            target="_blank"
          >
            <SVG
              aria-label="Land Rover"
              className={`${scope}-socialIcon`}
              role="img"
              src={`${process.env.PUBLIC_URL}/media/icon-landrover.svg`}
            />
          </a>
          <a
            className={`${scope}-socialLink`}
            href="https://www.facebook.com/landrover"
            rel="noreferrer"
            target="_blank"
          >
            <SVG
              aria-label="Facebook"
              className={`${scope}-socialIcon`}
              role="img"
              src={`${process.env.PUBLIC_URL}/media/icon-facebook.svg`}
            />
          </a>
          <a
            className={`${scope}-socialLink`}
            href="https://www.instagram.com/defender/"
            rel="noreferrer"
            target="_blank"
          >
            <SVG
              aria-label="Instagram"
              className={`${scope}-socialIcon`}
              role="img"
              src={`${process.env.PUBLIC_URL}/media/icon-instagram.svg`}
            />
          </a>
        </div>
        <div className={`${scope}-texts`}>
          <div className={`${scope}-textLine`}>
            © 2024 Land Rover Deutschland
          </div>
          <div className={`${scope}-textLine`}>
            <a
              className={`${scope}-link`}
              href="https://www.landrover.de/ownership/contact-us/impressum.html"
              rel="noreferrer"
              target="_blank"
            >
              Impressum
            </a>
          </div>
          <div className={`${scope}-textLine`}>
            <a
              className={`${scope}-link`}
              href="https://www.landrover.de/privacy-policy/index.html"
              rel="noreferrer"
              target="_blank"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
