import dayjs from 'dayjs';
import { Response, createServer } from 'miragejs';
import { EventTypeKey, EventTypeModel } from './EventTypeModel';
import recordedResponse from './api-response.json';

interface EventFixture {
  dates: {
    start: string;
    end: string;
  }[];
  typeId: string;
  key: EventTypeKey;
  slots: number;
}

const eventsFixtures: EventFixture[] = [
  {
    dates: [
      {
        start: '2023-08-22 00:00',
        end: '2023-08-27 00:00',
      },
    ],
    key: 'kite-cup',
    slots: 0,
    typeId: 'tbd',
  },
  {
    dates: [
      {
        start: '2023-08-05 00:00',
        end: '2023-08-05 01:30',
      },
      {
        start: '2023-08-12 00:00',
        end: '2023-08-12 01:30',
      },
      {
        start: '2023-08-19 00:00',
        end: '2023-08-19 01:30',
      },
      {
        start: '2023-08-26 00:00',
        end: '2023-08-26 01:30',
      },
    ],
    key: 'kite-workshop',
    slots: 3,
    typeId: '5ef1d4596307ac4ba09981ff00e9dd60e6a81894f3b234a42b570839dfeefb5e',
  },
  {
    dates: [
      {
        start: '2023-08-04 00:00',
        end: '2023-08-04 03:30',
      },
      {
        start: '2023-08-11 00:00',
        end: '2023-08-11 03:30',
      },
      {
        start: '2023-08-18 00:00',
        end: '2023-08-18 03:30',
      },
      {
        start: '2023-08-25 00:00',
        end: '2023-08-25 03:30',
      },
    ],
    key: 'mudflat-hiking',
    slots: 12,
    typeId: '5bbd82f7628f1110f9f0c5ce77dcdae8e97828cb0ce1741e5843100060623f60',
  },
  {
    dates: [
      {
        start: '2023-08-05 00:00',
        end: '2023-08-05 03:00',
      },
      {
        start: '2023-08-12 00:00',
        end: '2023-08-12 03:00',
      },
      {
        start: '2023-08-19 00:00',
        end: '2023-08-19 03:00',
      },
      {
        start: '2023-08-26 00:00',
        end: '2023-08-26 03:00',
      },
    ],
    key: 'nightsky-gazing',
    slots: 12,
    typeId: '91a4160f6b0060e515c4fb51c061d34c5648d3ed2aed6b51a78b7da358d23b04',
  },
];

export interface ApiEvent {
  eventId: number;
  eventName: string;
  eventTypeLink: string;
  signUpLink: string;
  slots: {
    available: number;
    total: number;
  };
  status: string;
  time: {
    end: number;
    start: number;
  };
}

export const origin = 'https://api.defender-sylt.de';

export function createMirageServer({ environment = 'development' } = {}) {
  let events: ApiEvent[] = [];
  let eventId = 0;

  if (process.env.REACT_APP_API_USE_RECORDED_RESPONSE === 'true') {
    events = recordedResponse;
  } else {
    eventsFixtures.forEach((data) => {
      const eventType = EventTypeModel.find(data.key);
      data.dates.forEach(({ start, end }) => {
        eventId++;
        events.push({
          eventId,
          eventName: eventType.attrs.name,
          eventTypeLink: `https://www.defender-sylt.de/events/?eventType=${data.typeId}`,
          signUpLink: `https://www.defender-sylt.de/events/?event=${eventId}`,
          slots: {
            available: data.slots,
            total: data.slots,
          },
          status: 'planned',
          time: {
            end: dayjs(end).valueOf() / 1000,
            start: dayjs(start).valueOf() / 1000,
          },
        });
      });
    });
  }

  const server = createServer({
    environment,

    urlPrefix: origin,

    routes() {
      this.passthrough((request) => {
        const { url } = request;
        return (
          url.startsWith(`${process.env.PUBLIC_URL}/media`) ||
          !url.startsWith(origin)
        );
      });

      this.get('/events', () => {
        return events;
      });

      this.get('/events/:eventId', (_schema, request) => {
        const eventId = parseInt(request.params.eventId);
        const event = events.find((event) => event.eventId === eventId);
        if (!event) {
          return new Response(404);
        }
        return event;
      });
    },
  });

  return server;
}
