import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Main from './components/Main/Main';
import './App.scss';

function App() {
  const scope = 'App';

  return (
    <BrowserRouter basename={process.env.REACT_APP_ROOT}>
      <I18nextProvider i18n={i18next}>
        <div className={`${scope}`}>
          <Main />
        </div>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
