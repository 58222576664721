import Lottie from 'lottie-react';
import { useState } from 'react';
import { useInView } from 'react-hook-inview';
import { Link, useNavigate } from 'react-router-dom';
import { EventTypeModel } from '../../data/EventTypeModel';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import introAnimationData from '../../lottie/intro.json';
import './LocationsMap.scss';
import TextBlock from '../TextBlock/TextBlock';

export interface Props {
  animationData: unknown;
  className?: string;
  showIntro?: boolean;
  text: string | string[];
  title: string;
}

export default function LocationsMap(props: Props) {
  const scope = 'LocationsMap';
  const systemDevice = useSystemDevice();
  const [isScrolledToAnimation, setIsScrolledToAnimation] = useState(false);
  const [isShowIntro, setIsShowIntro] = useState(props.showIntro);
  const navigate = useNavigate();
  const [ref] = useInView(
    {
      onEnter: () => {
        if (!isScrolledToAnimation) {
          setIsScrolledToAnimation(true);
        }
      },
      threshold: 0.1,
    },
    [isScrolledToAnimation],
  );
  const eventTypes = EventTypeModel.findAll();

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  return (
    <div className={className}>
      <div className={`${scope}-grid`}>
        <TextBlock
          className={`${scope}-textBlock`}
          text={props.text}
          title={props.title}
        />
        <div className={`${scope}-animation`} ref={ref}>
          <div className={`${scope}-animationMap`}>
            {isScrolledToAnimation && (
              <>
                {isShowIntro ? (
                  <Lottie
                    animationData={introAnimationData}
                    className={`${scope}-lottie`}
                    loop={false}
                    onComplete={() => setIsShowIntro(false)}
                  />
                ) : (
                  <Lottie
                    animationData={props.animationData}
                    className={`${scope}-lottie`}
                    loop={true}
                  />
                )}
                {eventTypes.map((eventType) => (
                  <Link
                    className={`${scope}-pageLink`}
                    key={eventType.key}
                    onClick={() => navigate(eventType.linkPath)}
                    style={{
                      left: `${eventType.attrs.positionOnMap.left}%`,
                      bottom: `${eventType.attrs.positionOnMap.bottom}%`,
                    }}
                    to={eventType.linkPath}
                    type="button"
                  ></Link>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
