import './EventInfo.scss';
import { EventTypeModel } from '../../data/EventTypeModel';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import EventInstructor from '../EventInstructor/EventInstructor';
import TextBlock from '../TextBlock/TextBlock';

export interface Props {
  className?: string;
  eventType: EventTypeModel;
}

function EventInfo(props: Props) {
  const scope = 'EventInfo';

  const systemDevice = useSystemDevice();
  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  return (
    <div className={className}>
      {props.eventType.attrs.info && (
        <TextBlock
          className={`${scope}-description`}
          text={props.eventType.attrs.info.text}
          title={props.eventType.attrs.info.title}
        />
      )}
      {props.eventType.attrs.instructor ? (
        <>
          <div className={`${scope}-divider`} />
          <EventInstructor
            className={`${scope}-instructor`}
            firstName={props.eventType.attrs.instructor.firstName}
            imageSrc={`${process.env.PUBLIC_URL}/media/events/${props.eventType.key}/instructor.webp`}
            lastName={props.eventType.attrs.instructor.lastName}
            text={props.eventType.attrs.instructor.intro}
          />
        </>
      ) : props.eventType.attrs.extraInfo ? (
        <>
          <div className={`${scope}-divider`} />
          <TextBlock
            className={`${scope}-description`}
            subtitle={props.eventType.attrs.extraInfo.subtitle}
            text={props.eventType.attrs.extraInfo.text}
            title={props.eventType.attrs.extraInfo.title}
          />
        </>
      ) : (
        <div className={`${scope}-spacer`} />
      )}
    </div>
  );
}

export default EventInfo;
