import { useEffect, useState } from 'react';
import { Controller, Pagination } from 'swiper';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import 'swiper/css';
import LinkButton from '../Button/LinkButton';
import { ChildProps } from '../ProgramSelector/ProgramSelector';
import TextBlock from '../TextBlock/TextBlock';
import './ProgramSelectorMobile.scss';

export default function ProgramSelectorMobile(props: ChildProps) {
  const scope = 'ProgramSelectorMobile';

  const systemDevice = useSystemDevice();
  const [swiper1, setSwiper1] = useState<SwiperClass>();
  const [swiper2, setSwiper2] = useState<SwiperClass>();

  useEffect(() => {
    if (swiper1 && !swiper1.destroyed) {
      swiper1.slideTo(0);
    }
    if (swiper2 && !swiper2.destroyed) {
      swiper2.slideTo(0);
    }
  }, [props.eventTypes, swiper1, swiper2]);

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  return (
    <div className={className}>
      <TextBlock
        className={`${scope}-intro`}
        text={props.introText}
        title={props.title}
      />

      <Swiper
        className={`${scope}-swiper`}
        controller={{ control: swiper2 }}
        modules={[Controller, Pagination]}
        onSwiper={setSwiper1}
        pagination={{
          bulletActiveClass: `${scope}-paginationItem--active`,
          bulletClass: `${scope}-paginationItem`,
          clickable: true,
          clickableClass: `${scope}-pagination`,
          type: 'bullets',
        }}
        slidesPerView={1}
        spaceBetween={20}
      >
        {props.eventTypes.map((eventType) => (
          <SwiperSlide className={`${scope}-slide`} key={eventType.key}>
            <img
              alt=""
              className={`${scope}-image`}
              role="presentation"
              src={eventType.getImageSrc(
                'program-selector',
                SYSTEM_DEVICE.MOBILE,
              )}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        className={`${scope}-swiper`}
        controller={{ control: swiper1 }}
        modules={[Controller]}
        onSwiper={setSwiper2}
        slidesPerView={1}
        spaceBetween={20}
      >
        {props.eventTypes.map((eventType) => (
          <SwiperSlide className={`${scope}-slide`} key={eventType.key}>
            <TextBlock
              className={`${scope}-sliderText`}
              text={eventType.attrs.programSelectorText}
              title={eventType.attrs.name}
            />
            <LinkButton text="Mehr informationen" to={eventType.linkPath} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
