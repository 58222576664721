import React from 'react';
import './TextBlock.scss';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
  collapsedText?: string | string[];
  subtitle?: string;
  text?: string | string[];
  title: string | string[];
}

const TextBlock: React.FC<Props> = (props) => {
  const scope = 'TextBlock';

  const { t } = useTranslation();
  const systemDevice = useSystemDevice();
  // Can't change anything about the library code, so muting the linter.
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();

  const className = [
    scope,
    `${scope}--${systemDevice}`,
    `${scope}--${isExpanded ? 'expanded' : 'collapsed'}`,
    props.className,
  ]
    .filter((className) => className)
    .join(' ');

  const textArray = (
    Array.isArray(props.text) ? props.text : [props.text]
  ).filter(Boolean);
  let collapsedTextArray: string[] = [];
  if (props.collapsedText) {
    collapsedTextArray = Array.isArray(props.collapsedText)
      ? props.collapsedText
      : [props.collapsedText];
    if (systemDevice === SYSTEM_DEVICE.DESKTOP) {
      collapsedTextArray.forEach((item) => {
        textArray.push(item);
      });
      collapsedTextArray = [];
    }
  }
  const titleArray = Array.isArray(props.title) ? props.title : [props.title];

  return (
    <section className={className}>
      <div className={`${scope}-header`}>
        {props.subtitle && (
          <p className={`${scope}-subtitle`}>{props.subtitle}</p>
        )}
        <h2 className={`${scope}-title`}>
          {titleArray.map((titleRow, index) => (
            <div key={index}>
              {index > 0 && <br />}
              {titleRow}
            </div>
          ))}
        </h2>
      </div>
      {textArray.length > 0 && (
        <div className={`${scope}-body`}>
          {textArray.map((paragraph, index) => (
            <p className={`${scope}-paragraph`} key={index}>
              {paragraph}
            </p>
          ))}
          {collapsedTextArray.length > 0 && (
            <div {...getCollapseProps()} className={`${scope}-collapsible`}>
              {collapsedTextArray.map((paragraph, index) => (
                <p className={`${scope}-paragraph`} key={index}>
                  {paragraph}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
      {collapsedTextArray.length > 0 && (
        <button
          {...getToggleProps()}
          className={`${scope}-toggleButton`}
          onClick={() => setExpanded((prevIsExpanded) => !prevIsExpanded)}
          type="button"
        >
          {isExpanded ? t('less') : t('find_out_more')}
          <SVG
            className={`${scope}-arrow`}
            src={`${process.env.PUBLIC_URL}/media/chevron-right.svg`}
          />
        </button>
      )}
    </section>
  );
};

export default TextBlock;
