import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import SVG from 'react-inlinesvg';
import './Button.scss';

export interface Props
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  text?: string;
}

function AnchorButton(props: Props) {
  const scope = 'Button';
  const className = [scope, props.className].join(' ');
  return (
    <a {...props} className={className}>
      <SVG
        className={`${scope}-arrow`}
        role="presentation"
        src={`${process.env.PUBLIC_URL}/media/arrow-right.svg`}
      />
      {props.children ?? props.text}
    </a>
  );
}

export default AnchorButton;
