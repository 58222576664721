import './Content.scss';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import TextBlock from '../TextBlock/TextBlock';
import KiteCupRichSection from './RichSection';

export interface Props {
  className?: string;
}

interface SectionData {
  gridData?: [string, string][];
  imageSrc?: string;
  subtitle?: string;
  text?: string | string[];
  title?: string;
}

function KiteCupContent(props: Props) {
  const systemDevice = useSystemDevice();
  const scope = 'KiteCupContent';

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  const richSections: SectionData[] = [
    {
      imageSrc: `${process.env.PUBLIC_URL}/media/events/kite-cup/${systemDevice}/rich-1.webp`,
      subtitle: 'Quick Fire Interviews',
      title: 'Gemeinsam mit den profis',
      text: [
        'Erleben Sie spontane Interviews mit Top-Athleten in unseres Defender Lounge. Der Defender wird dabei zur beeindruckenden Kulisse, die für eine fesselnde Atmosphäre sorgt. Seien Sie live dabei, wenn spannende Gespräche stattfinden und lassen Sie sich von der einzigartigen Szenerie mitreißen!',
        'DI / MI / DO / FR / SA / SO zw. 10:00-13:00 Uhr*',
      ],
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/media/events/kite-cup/${systemDevice}/rich-2.webp`,
      subtitle: 'Ocean cinema',
      title: 'Defender movie night',
      text: [
        'Lassen Sie den Tag mit einem unvergesslichen Kinoerlebnis ausklingen. Genießen Sie den Sonnenuntergang mit der European Outdoor Film Tour und machen Sie es sich bequem für ein spannendes Kinoerlebnis unter freiem Himmel.',
        'DI / MI / DO / FR / SA 18:00-19:00 Uhr*',
      ],
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/media/events/kite-cup/${systemDevice}/rich-3.webp`,
      subtitle: 'Live-musik am abend',
      title: 'Defender music session',
      text: [
        'Die Sonne geht unter, die Musik wird lauter. Ein Defender steht im Hintergrund. Lassen Sie sich von ausgewählten Künstlern den Abend verschönern. Genießen Sie ein entspanntes Erlebnis, das den spannenden Tag ausklingen lässt.',
        'DI 19:00-22:00 Uhr*\nMI / DO 17:00-18:00 Uhr*\nFR / SA 19:00-22:00 Uhr*',
      ],
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/media/events/kite-cup/${systemDevice}/rich-4.webp`,
      subtitle: 'Inspiration talk',
      title: 'Defender get together',
      text: [
        'Lassen Sie sich inspirieren und faszinieren. Wir laden Sie zu lockeren Gesprächen mit den Stars der Kitesurf Community ein. Ein spannender Austausch, der auch Sie bestimmt bereichern wird.',
        'DI 15:30-16:30 Uhr*\nMI 15:00-16:00 Uhr*\nSA / SO 15:00-16:00 Uhr*',
      ],
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/media/events/kite-cup/${systemDevice}/rich-5.webp`,
      subtitle: 'Signierstunde mit Athleten des Kitesurf Worldcups',
      title: 'Signature Hour',
      text: [
        'Sichern Sie sich das perfekte Andenken an den Kitesurf Worldcup: Ein limitiertes Poster, signiert von einem Athleten des Kitesurf Worldcups. Ein schönes Andenken, das Sie immer an dieses einzigartige Erlebnis erinnern wird.',
        'MI - SO 16:00-17:00 Uhr*',
        "* Bitte beachten Sie, dass wetterbedingt Events verschoben oder abgesagt werden können. Zudem hängen die Events 'Pro Talk' und 'Signature Hour' von den Wettkampfzeiten der Athleten ab.",
      ],
    },
  ];

  return (
    <div className={className}>
      <div className={`${scope}-darkSection`}>
        <div className={`${scope}-contentBlock`} data-padding-small>
          <TextBlock
            className={`${scope}-halfWidth ${scope}-jumboTextBlock`}
            text="Vom 20.08. bis zum 25.08.2024 können Sie die spannende und adrenalingeladene Jagd nach dem Defender Kitesurf World Cup Titel hautnah miterleben. Seien Sie am Brandenburger Strand in Westerland dabei, wenn die besten Kitesurfer der Welt um den begehrten Titel kämpfen und ihre spektakulären Tricks und Stunts auf dem Wasser vollführen."
            title="Defender kitesurf world cup 20.-25.08.2024"
          />
        </div>
      </div>
      {richSections.map((section, index) => (
        <div key={index}>
          <KiteCupRichSection
            className={`${scope}-richSection ${scope}-contentBlock`}
            imageSrc={section.imageSrc}
            subtitle={section.subtitle}
            text={section.text}
            title={section.title}
          />
          {index < richSections.length - 1 && (
            <div className={`${scope}-dividerBlock`}>
              <hr className={`${scope}-divider`} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default KiteCupContent;
