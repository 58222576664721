import React from 'react';
import { useTranslation } from 'react-i18next';
import './EventRegistration.scss';
import { EventInstanceModel } from '../../data/EventInstanceModel';
import { EventTypeModel } from '../../data/EventTypeModel';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import Button from '../Button/Button';

export interface Props {
  className?: string;
  eventType: EventTypeModel;
}

const EventRegistration: React.FC<Props> = (props) => {
  const scope = 'EventRegistration';

  const { t } = useTranslation();
  const systemDevice = useSystemDevice();

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  const clickRegisterButton = () => {
    window.open(props.eventType.eventTypeLink);
  };

  const currentDate = new Date();
  let currentEvent: EventInstanceModel | undefined =
    props.eventType.events.find((event) => {
      return event.time.start >= currentDate && event.time.end <= currentDate;
    });
  if (!currentEvent) {
    if (currentDate < props.eventType.events[0]?.time.start) {
      currentEvent = props.eventType.events[0];
    } else {
      currentEvent = props.eventType.events[props.eventType.events.length - 1];
    }
  }

  return (
    <section className={className}>
      <h1 className={`${scope}-title`}>{props.eventType.attrs.name}</h1>
      <div className={`${scope}-content`}>
        <div className={`${scope}-field`}>
          <strong className={`${scope}-fieldName`}>
            {t('meeting_point')}:
          </strong>{' '}
          {props.eventType.attrs.meetingPoint}
        </div>
        <div className={`${scope}-field`}>
          <strong className={`${scope}-fieldName`}>{t('duration')}:</strong>{' '}
          {props.eventType.attrs.duration}
        </div>
        <div className={`${scope}-field`}>
          <strong className={`${scope}-fieldName`}>{t('costs')}:</strong>{' '}
          {t('for_free')}
        </div>
        <Button
          className={`${scope}-button`}
          onClick={clickRegisterButton}
          text={t('register_now')}
        />
      </div>
    </section>
  );
};

export default EventRegistration;
