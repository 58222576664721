import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import LocationsMap from '../../components/LocationsMap/LocationsMap';
import ProgramSelector from '../../components/ProgramSelector/ProgramSelector';
import Teaser from '../../components/Teaser/Teaser';
import TextBlock from '../../components/TextBlock/TextBlock';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import homeAnimationData from '../../lottie/home.json';
import './Home.scss';

function Home() {
  const scope = 'Home';
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  const className = [scope, `${scope}--${systemDevice}`].join(' ');
  const mapText = t('locations_map_description');
  const mapTitle = t('locations_map_title');

  const teaser1Text =
    systemDevice === SYSTEM_DEVICE.DESKTOP
      ? 'Ein Fahrzeug, das Maßstäbe setzt. Ob für Familien, Abenteurer oder Entdecker, der Defender bietet eine nie dagewesene Robustheit gepaart mit modernem Komfort. Ausgestattet mit innovativen Technologien sowie einer breiten Auswahl an Varianten und Ausstattungen erfüllt der Defender sämtliche Wünsche.'
      : 'Der Defender OCTA verkörpert die perfekte Fusion aus Kraft, Präzision und Geländegängigkeit. Ausgestattet mit einem V8-Twin-Turbo-Motor und innovativer 6D Dynamics Luftfederung bietet der OCTA ein unvergleichliches Fahrerlebnis. Erleben Sie den leistungsstärksten Defender, der höchste Ansprüche an Luxus und Performance erfüllt.';

  return (
    <div className={className}>
      <Header
        imageSrc={`${process.env.PUBLIC_URL}/media/home-${systemDevice}.webp`}
      />
      <div className={`${scope}-contentBlock`} data-padding-small>
        <TextBlock
          className={`${scope}-halfWidth ${scope}-jumboTextBlock`}
          text="Entdecken Sie vom 10. bis 25. August die Schönheit der Nordseeinsel Sylt im Rahmen der Defender Experience. Freuen Sie sich auf Küstenausflüge, spannende Probefahrten und entspannte Stunden in der Defender Lounge am Strand von Westerland."
          title="DEFENDER EXPERIENCE auf SYLT"
        />
      </div>
      <div className={`${scope}-lightSection`}>
        <div className={`${scope}-contentBlock`}>
          <ProgramSelector />
        </div>
      </div>
      <div className={`${scope}-contentBlock`}>
        <LocationsMap
          animationData={homeAnimationData}
          showIntro={true}
          text={mapText}
          title={mapTitle}
        />
      </div>
      <div className={`${scope}-darkSection`}>
        <div className={`${scope}-contentBlock`}>
          <Teaser
            buttonText="Mehr erfahren"
            className={`${scope}-teaser1`}
            imageSrc={`${process.env.PUBLIC_URL}/media/teaser-1-${systemDevice}.webp`}
            text={teaser1Text}
            title="Tauchen Sie ein in die Welt des Defender"
            url="https://www.landrover.de/defender/index.html"
          />
        </div>
      </div>
      <div className={`${scope}-lightSection`}>
        <div className={`${scope}-contentBlock`}>
          <Teaser
            buttonText="Jetzt Registrieren"
            className={`${scope}-teaser2`}
            imageSrc={`${process.env.PUBLIC_URL}/media/teaser-2-${systemDevice}.webp`}
            reverseDesktop={true}
            title="Sie wollen auf dem Laufenden bleiben? Dann kommt unser Newsletter genau richtig."
            url="https://forms.landrover.de/landr-de-de/servlet/SmartForm.html?formCode=lr-kmi2-de-de"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
