import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import de from '../../assets/json/locale-de.json';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import EventPage from '../../pages/Event/EventPage';
import Home from '../../pages/Home/Home';
import './Main.scss';
import Sustainability from '../../pages/Sustainability/Sustainability';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

void i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'de',
  supportedLngs: ['de'],
  resources: {
    de: {
      translation: de,
    },
  },
});

export default function Main() {
  const scope = 'Main';
  const systemDevice = useSystemDevice();
  const className = [scope, `${scope}--${systemDevice}`].join(' ');

  return (
    <main className={className}>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="event/:eventKey" element={<EventPage />} />
        <Route path="sustainability" element={<Sustainability />} />
        <Route path="*" element={<Navigate to={''} replace />} />
      </Routes>
    </main>
  );
}
