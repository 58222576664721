import { EventTypeKey, EventTypeModel } from '../../data/EventTypeModel';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import ProgramSelectorDesktop from '../ProgramSelectorDesktop/ProgramSelectorDesktop';
import ProgramSelectorMobile from '../ProgramSelectorMobile/ProgramSelectorMobile';

export interface Props {
  className?: string;
  eventType?: EventTypeModel;
}

export interface ChildProps {
  className?: string;
  eventTypes: EventTypeModel[];
  introText?: string;
  title: string;
}

export default function ProgramSelector(props: Props) {
  const systemDevice = useSystemDevice();
  const { eventType, ...restProps } = props;
  const eventTypesKeys: EventTypeKey[] = eventType
    ? eventType.attrs.recommendedEventsKeys
    : [
        'kite-workshop',
        'test-drive',
        'nightsky-gazing',
        'mudflat-hiking',
        'kite-cup',
      ];
  const eventTypes = eventTypesKeys.map((key) => EventTypeModel.find(key));

  const childProps = {
    eventTypes,
    introText: eventType
      ? undefined
      : 'Erhalten Sie hier alle Informationen zu unseren exklusiven Programmpunkten und sichern Sie sich Ihren Platz. Die Teilnehmerzahl ist begrenzt – buchen Sie jetzt!',
    title: eventType
      ? 'Das könnte sie auch interessieren'
      : 'Abenteuer erleben',
    ...restProps,
  };

  return systemDevice === SYSTEM_DEVICE.DESKTOP ? (
    <ProgramSelectorDesktop {...childProps} />
  ) : (
    <ProgramSelectorMobile {...childProps} />
  );
}
