import './Teaser.scss';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import Button from '../Button/Button';

export interface Props {
  buttonText: string;
  className?: string;
  footnote?: string;
  imageSrc: string;
  reverseDesktop?: boolean;
  text?: string;
  title?: string;
  url: string;
}

function Teaser(props: Props) {
  const scope = 'Teaser';

  const systemDevice = useSystemDevice();
  const className = [
    scope,
    `${scope}--${systemDevice}`,
    props.footnote && `${scope}--withFootnote`,
    props.className,
  ]
    .filter((className) => className)
    .join(' ');

  const clickButton = () => {
    window.open(props.url);
  };

  return (
    <section
      className={className}
      data-reverse-desktop={props.reverseDesktop || undefined}
    >
      <div className={`${scope}-imageBlock`}>
        <img
          alt=""
          className={`${scope}-image`}
          role="presentation"
          src={props.imageSrc}
        />
      </div>
      <div className={`${scope}-textBlock`}>
        {props.title && <div className={`${scope}-title`}>{props.title}</div>}
        {props.text && <div className={`${scope}-text`}>{props.text}</div>}
        <div className={`${scope}-buttonContainer`}>
          <Button
            className={`${scope}-button`}
            onClick={clickButton}
            text={props.buttonText}
          />
        </div>
        {props.footnote && (
          <div className={`${scope}-footnote`}>{props.footnote}</div>
        )}
      </div>
    </section>
  );
}

export default Teaser;
