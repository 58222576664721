import { useEffect, useState } from 'react';

export enum SYSTEM_DEVICE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export function useSystemDevice() {
  const [systemDeviceState, setSystemDevice] = useState(detectDevice());

  useEffect(() => {
    function handleResize() {
      setSystemDevice(detectDevice());
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return systemDeviceState;
}

const detectDevice = (): SYSTEM_DEVICE => {
  const userAgent = navigator.userAgent;
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(userAgent);
  const isAndroid = /(?:Android)/.test(userAgent);
  const mobileMaxWidth = '760px';
  if (
    isIOS ||
    isAndroid ||
    window.matchMedia(`(max-width: ${mobileMaxWidth})`).matches
  ) {
    return SYSTEM_DEVICE.MOBILE;
  }
  return SYSTEM_DEVICE.DESKTOP;
};
