import './EventPage.scss';
import { Navigate, useParams } from 'react-router-dom';
import EventInfo from '../../components/EventInfo/EventInfo';
import EventRegistration from '../../components/EventRegistration/EventRegistration';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import KiteCupContent from '../../components/KiteCup/Content';
import LocationsMap from '../../components/LocationsMap/LocationsMap';
import ProgramSelector from '../../components/ProgramSelector/ProgramSelector';
import { EventTypeModel, isValidEventKey } from '../../data/EventTypeModel';
import { useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
}

function EventPage(props: Props) {
  const scope = 'EventPage';

  const systemDevice = useSystemDevice();
  const params = useParams<'eventKey'>();

  if (!isValidEventKey(params.eventKey)) {
    return <Navigate to={'/'} replace />;
  }
  const eventType = EventTypeModel.find(params.eventKey);
  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  const headerImageSrc = eventType.getImageSrc('main', systemDevice);

  return (
    <div className={className}>
      <Header imageSrc={headerImageSrc} />
      {eventType.key === 'kite-cup' ? (
        <KiteCupContent />
      ) : (
        <>
          <div className={`${scope}-darkSection`}>
            <EventRegistration
              eventType={eventType}
              className={`${scope}-contentBlock`}
            />
          </div>
          <div className={`${scope}-lightGreySection`}>
            <EventInfo
              eventType={eventType}
              className={`${scope}-contentBlock`}
            />
          </div>
          {eventType.attrs.mapInfo && (
            <LocationsMap
              className={`${scope}-contentBlock`}
              animationData={eventType.attrs.animationData}
              text={eventType.attrs.mapInfo.text}
              title={eventType.attrs.mapInfo.title}
            />
          )}
        </>
      )}
      <div className={`${scope}-lightGreySection`}>
        <ProgramSelector
          className={`${scope}-contentBlock`}
          eventType={eventType}
        />
      </div>
      <Footer />
    </div>
  );
}

export default EventPage;
