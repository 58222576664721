import React, { MouseEvent } from 'react';
import SVG from 'react-inlinesvg';
import './Button.scss';

export interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  text?: string | null;
}

function Button(props: Props) {
  const scope = 'Button';
  const className = [scope, props.className].join(' ');
  return (
    <button
      className={className}
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
    >
      <SVG
        className={`${scope}-arrow`}
        role="presentation"
        src={`${process.env.PUBLIC_URL}/media/arrow-right.svg`}
      />
      {props.text}
    </button>
  );
}

export default Button;
