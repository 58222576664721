import React from 'react';
import './EventInstructor.scss';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
  firstName: string;
  imageSrc: string;
  lastName: string;
  text: string;
}

const EventInstructor: React.FC<Props> = (props) => {
  const scope = 'EventInstructor';

  const systemDevice = useSystemDevice();

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  const blocks = {
    description: <div className={`${scope}-description`}>{props.text}</div>,
    image: (
      <img alt="photo" className={`${scope}-image`} src={props.imageSrc} />
    ),
    info: (
      <div className={`${scope}-info`}>
        <div className={`${scope}-subtitle`}>mit</div>
        <h2 className={`${scope}-name`}>
          {props.firstName} {props.lastName}
        </h2>
      </div>
    ),
  };

  return (
    <section className={className}>
      {systemDevice === SYSTEM_DEVICE.DESKTOP ? (
        <>
          {blocks.image}
          <div className={`${scope}-rightBlock`}>
            {blocks.info}
            {blocks.description}
          </div>
        </>
      ) : (
        <>
          <div className={`${scope}-topBlock`}>
            {blocks.image}
            {blocks.info}
          </div>
          {blocks.description}
        </>
      )}
    </section>
  );
};

export default EventInstructor;
