import './RichSection.scss';
import { useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
  imageSrc?: string;
  subtitle?: string;
  text?: string | string[];
  title?: string;
}

function KiteCupRichSection(props: Props) {
  const systemDevice = useSystemDevice();
  const scope = 'KiteCupRichSection';

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  let textArray: string[] = [];
  if (Array.isArray(props.text)) {
    textArray = props.text;
  } else if (props.text) {
    textArray.push(props.text);
  }
  const groupedTextLines = textArray.map((text) => text.split('\n'));

  return (
    <section className={className}>
      <div className={`${scope}-textBlock`}>
        <div className={`${scope}-contentBody`}>
          {(props.subtitle || props.title) && (
            <div className={`${scope}-header`}>
              {props.title && (
                <div className={`${scope}-title`}>{props.title}</div>
              )}
              {props.subtitle && (
                <div className={`${scope}-subtitle`}>{props.subtitle}</div>
              )}
            </div>
          )}
          {groupedTextLines.length > 0 && (
            <div className={`${scope}-content`}>
              {groupedTextLines.map((lines, index) => (
                <div key={index}>
                  {lines.map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={`${scope}-imageBlock`}>
        {props.imageSrc && (
          <img
            alt=""
            className={`${scope}-image`}
            role="presentation"
            src={props.imageSrc}
          />
        )}
      </div>
    </section>
  );
}

export default KiteCupRichSection;
