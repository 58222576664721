import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import LinkButton from '../Button/LinkButton';
import { ChildProps } from '../ProgramSelector/ProgramSelector';
import TextBlock from '../TextBlock/TextBlock';
import './ProgramSelectorDesktop.scss';

export default function ProgramSelectorDesktop(props: ChildProps) {
  const scope = 'ProgramSelectorDesktop';

  const systemDevice = useSystemDevice();
  const [activeEventIndex, setActiveEventIndex] = useState(0);

  const activeEvent = props.eventTypes[activeEventIndex];

  const className = [scope, `${scope}--${systemDevice}`, props.className]
    .filter((className) => className)
    .join(' ');

  return (
    <div className={className}>
      <TextBlock
        className={`${scope}-intro`}
        text={props.introText}
        title={props.title}
      />

      <div className={`${scope}-main`}>
        <div className={`${scope}-imageContainer`}>
          <img
            alt=""
            className={`${scope}-image`}
            role="presentation"
            src={activeEvent.getImageSrc(
              'program-selector',
              SYSTEM_DEVICE.DESKTOP,
            )}
          />
        </div>
        <div className={`${scope}-list`}>
          {props.eventTypes.map((item, index) => (
            <div
              className={`${scope}-item`}
              data-active={index === activeEventIndex ? '' : undefined}
              key={item.key}
            >
              <SVG
                className={`${scope}-icon`}
                aria-label="Select program"
                onClick={() => setActiveEventIndex(index)}
                role="button"
                src={`${process.env.PUBLIC_URL}/media/circle-${
                  activeEventIndex === index ? 'minus' : 'plus'
                }.svg`}
              />
              <div className={`${scope}-itemContent`}>
                <div
                  className={`${scope}-title`}
                  onClick={() => setActiveEventIndex(index)}
                >
                  {item.attrs.name}
                </div>
                <div className={`${scope}-text`}>
                  {item.attrs.programSelectorText}
                </div>
                <LinkButton
                  className={`${scope}-button`}
                  text="Mehr informationen"
                  to={item.linkPath}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
