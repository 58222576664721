import SVG from 'react-inlinesvg';
import './Button.scss';
import { Link, LinkProps } from 'react-router-dom';

export interface Props extends LinkProps {
  text?: string;
}

function LinkButton(props: Props) {
  const scope = 'Button';
  const className = [scope, props.className].join(' ');
  return (
    <Link {...props} className={className}>
      <SVG
        className={`${scope}-arrow`}
        role="presentation"
        src={`${process.env.PUBLIC_URL}/media/arrow-right.svg`}
      />
      {props.text}
    </Link>
  );
}

export default LinkButton;
